import { lazy } from "react";

export const routes = [
  {
    path: "/auth/login",
    Component: lazy(() => import("@pages/Login")),
    exact: true,
  },
  {
    path: "/auth/forgot-password",
    Component: lazy(() => import("@pages/ForgotPassword")),
    exact: true,
  },
  {
    path: "/auth/register",
    Component: lazy(() => import("@pages/Register")),
    exact: true,
  },
  {
    path: "/auth/verification",
    Component: lazy(() => import("@pages/Verification")),
    exact: true,
  },
  {
    path: "/auth/invitation",
    Component: lazy(() => import("@pages/Invitation")),
    exact: true,
  },
  {
    path: "/auth/404",
    Component: lazy(() => import("@pages/404")),
    exact: true,
  },
];

export const routesDashboard = [
  {
    path: "/dashboard/home",
    Component: lazy(() => import("@pages/Dashboard")),
    exact: true,
  },
  {
    path: "/dashboard/profile",
    Component: lazy(() => import("@pages/Profile")),
    exact: true,
  },
  {
    path: "/dashboard/change-password",
    Component: lazy(() => import("@pages/ChangePassword")),
    exact: true,
  },
  {
    path: "/dashboard/logout",
    Component: lazy(() => import("@pages/Logout")),
    exact: true,
  },
];
